<template>
  <div 
    class="
      duck-form 
      add--project--form
    "
  >
    <form
      @submit.prevent="saveForm"
      autocomplete="off"
    >
      <fieldset 
        class="
          form-block
          add--project--block
        "
      >
        <div 
          class="form-container"
        >
          <div class="input">
            <Label 
              text="ชื่อเจ้าของโครงการ" 
              :required=true
            />

            <SuggestMemberNameInput 
              v-model="memberId" 
            />
          </div>

          <ProjectSubmitBlock text="ไปขั้นตอนต่อไป" />
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
// Load library
import {apiRequest} from '@/utils/axios/axiosInstance.js';

// Load components
import Label from '@/components/common/form/Label.vue';
import ProjectSubmitBlock from '@/components/project/form/button/ProjectSubmitBlock.vue';
import SuggestMemberNameInput from '@/components/member/form/input/SuggestMemberNameInput.vue';

// Load CSS
import '@/assets/css/project/project-form.css';


export default {
  components: {
    Label,
    ProjectSubmitBlock,
    SuggestMemberNameInput,
  },
  data() {
    return {
      projectId: '',
      memberId: '',
    }
  },

  /**
  * Initial this view when created
  *   set PageTitle and hide MainLoader
  */ 
  created() {
    this.projectId = this.$route.params.id;
    
    // set page title
    this.$store.commit('setPageTitle', 'Add Project');
    this.$store.commit('setHeadTitle', 'Add Project');

    // hide main loader
    this.$store.commit('setLoaderShow', false);
  },
  methods: {
    /**
    * save Project Owner (MemberId) to database by API request
    */
   saveForm:function() {
     if(this.memberId === '') {
       alert('กรุณาเลือกชื่อเจ้าของโครงการให้ถูกต้อง !!!');
      } else {
        // Show main loader
        this.$store.commit('setLoaderShow', true);

        apiRequest
          .patch(
            '/project/',
            {
              project_id: this.projectId,
              owner: this.memberId //owner
            }
          )
          .then( (res) => {
            if (res.data.status == 200) {
              this.$router.push('/project/add/step2/' + this.projectId);
            } else {
              console.log(res.data);
              alert('เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณารีเฟรชหน้านี้และลองใหม่อีกครั้ง !!!');
            }
          });
      }
    },
  }
}
</script>